import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App.js';
import TicketItem from '../../components/ui/TicketItem.jsx';
import routes from '../../routes/routes.js';

export default function LandingEventTickets(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const { state: authState } = React.useContext(AppContext);

  return (
    <div className={`landing-event-tickets d-flex flex-column`}>
      <h3>{props.text}</h3>
      <div
        className={`landing-event-tickets-container d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center`}
      >
        {props.tickets?.map((item, index) => {
          return (
            <TicketItem
              key={item.id}
              isFinished={props.events?.isFinished}
              ticketStatus={item.ticketStatus ? item.ticketStatus : null}
              eventStatus={props.id ? null : props.events?.status}
              ticketImg={item.image ? item.image : null}
              eventImg={
                props.events?.images.cover ? props.events?.images.cover : null
              }
              currency={item.currency?.symbol}
              ticketCategory={item.category?.name}
              ticketName={item.name}
              eventName={props.events?.name}
              eventDate={props.events?.dateEvent}
              eventAddress={props.events?.location}
              ticketPrice={
                item.price
                  ? item.currency?.id === 2
                    ? item.currency?.symbol + Number(item.price).toFixed(2)
                    : Number(item.price).toFixed(2) + item.currency?.symbol
                  : null
              }
              ticketContent={item.extraContent}
              totalTickets={props.id ? null : item.amount}
              availableTickets={props.id ? null : item.amount}
              secondaryMarketUser={
                props.id
                  ? item.ticketsBuyers !== null && item.ticketsBuyers
                  : null
              }
              buttonActive={true}
              function={
                item.amount !== '0'
                  ? props.id
                    ? () =>
                        props.navigate(
                          window.location.pathname + '/resale/' + item.id
                        )
                    : () =>
                        props.navigate(window.location.pathname + '/' + item.id)
                  : null
              }
              ticketPaused={false}
              ticketActivationDate={null}
              saleOpen={
                props.ticketSaleOpen.length
                  ? props.ticketSaleOpen?.filter(
                      (ticket) => ticket.id === item.id
                    )[0]?.saleOpen
                  : null
              }
              setTicketSaleOpen={null}
              buttonFunction={
                item.amount !== '0'
                  ? props.id
                    ? authState.isAuthenticated
                      ? (event) => {
                          props.navigate(
                            window.location.pathname + '/checkout/' + item.id
                          );
                          event.stopPropagation();
                        }
                      : (event) => {
                          props.navigate(routes.auth);
                          event.stopPropagation();
                        }
                    : (event) => {
                        props.openSale(Number(item.id));
                        event.stopPropagation();
                      }
                  : null
              }
              id={item.id}
              ticketSaleQuantity={
                props.ticketSaleQuantity.length
                  ? props.ticketSaleQuantity?.filter(
                      (ticket) => ticket.id === item.id
                    )[0]?.quantity
                  : null
              }
              changeQuantity={props.changeQuantity}
              landing={true}
            />
          );
        })}
      </div>
    </div>
  );
}
